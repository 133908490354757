import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from '../contexts/UserContext.js';
import { login } from '../api/api.js';
import balesimage from '../images/balesimage.jpeg';
import logo from '../images/svtrading.jpg';

const Loginpage = () => {
  const [username, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const cookies = useMemo(() => new Cookies(), []);
  const currentYear = new Date().getFullYear();

  const { setUser } = useUser(); // Import setUser from UserContext

  useEffect(() => {
    const jwtToken = cookies.get('jwt');

    if (jwtToken) {
      try {
        const decoded = jwtDecode(jwtToken);

        if (decoded.exp * 1000 > Date.now()) {
          navigate('/homepage', {
            state: { username: decoded.username, username: decoded.username },
          });
        } else {
          cookies.remove('jwt');
        }
      } catch (error) {
        console.error('Error decoding JWT:', error);
      }
    }
  }, [cookies, navigate]);

  const submitloginform = async (e) => {
    try {
      e.preventDefault();
      const userdetails = { username, password };
      const response = await login(userdetails);
      if (response.status === 200) {
        cookies.set('jwt', response.data.token, { path: '/' });
        cookies.set('designation', response.data.designation, { path: '/' }); 
        
        const token = response.data.token;
        const decoded = jwtDecode(token);

        const userid = decoded.userId;
        localStorage.setItem('userid', userid);
        
        // Store user data in local storage
        localStorage.setItem('token', token);
        localStorage.setItem('designation', decoded.designation);
        localStorage.setItem('username', decoded.username);
        localStorage.setItem('username', decoded.username);
        localStorage.setItem('department', decoded.department);
        localStorage.setItem('phone', decoded.phone);
        localStorage.setItem('credit_limit', decoded.credit_limit);
        localStorage.setItem('name', decoded.name);

        console.log("Decoded token:", decoded);

        setUser({ username: decoded.username, designation: decoded.designation });
        
        toast.success('Login successful!');
        navigate('/homepage');
      } else {
        toast.error('Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('Error on login page:', error);
      toast.error('Login failed. Please check your credentials.');
    }
  };

  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className='d-none d-md-flex col-md-6 justify-content-center align-items-center'>
            <div className='w-50 boxshadow'>
              <img className='img-fluid rounded' src={logo} alt="Bales" style={{width: '3rem'}} />
              <p className="mt-4" style={{fontSize: '1.7rem'}}>Welcome to SV Trading LTD Login to your Account</p>
              <form className='' onSubmit={submitloginform}>
                <div className="mb-3">
                  <input
                    className="form-control"
                    type="text"
                    placeholder='Enter your username'
                    value={username}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    className="form-control"
                    type="password"
                    placeholder='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <button className="btn btn-dark w-100" type="submit">Login</button>
              </form>
              <p className="mt-4 text-center">Forgot your Password? Contact Admin</p>
              <ToastContainer />
            </div>
          </div>       
        
          <div className='col-md-6 d-none d-md-block'>
              <img className='img-fluid' src={balesimage} alt="Bales" />
          </div>

        </div>
      </div>
    </>
  );
};

export default Loginpage;
