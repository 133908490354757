import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../components/Sidepanel';
import Navbar from '../components/Navbar';
import Dashboard from '../pages/dashboard/Dashboard';
import Users from '../pages/homepage/Users';
import Customers from '../pages/homepage/Customers';
import Tasks from '../pages/homepage/Tasks';
import Notes from '../pages/homepage/Notes';
import Orders from '../pages/homepage/Orders'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchContainerDetails, deleteItemMaster, deleteContainerFromMaster, additemtoContainer, receiveContainer, fetchingAllContainerItems } from '../api/api';

export default function ContainerDetail() {
  const { id } = useParams(); // Get the container id from the route parameters
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [searchQuery, setSearchQuery] = useState('');
  const [conststatus, setConststatus] = useState(0);
  const [trueqty, receivedqty] = useState(0)

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  // logic 
  const [container, setContainers] = useState([]);
  const [formData, setFormData] = useState({
    contain: '',
    amount: '',
    username: localStorage.getItem('username')
  });

  useEffect(() => {
    fetchContainer();
    fetchContainer2()
  }, []);

  const fetchContainer = async () => {
    try {
      let container = await fetchContainerDetails(id);
      console.log('Fetched container1:', container);
      setContainers(container);
      setConststatus(container[0].status)
    } catch (error) {
      console.error('Error fetching container:', error);
    }
  };

  const fetchContainer2 = async () => {
    try {
      // Try fetching the first container
      let container = await fetchContainerDetails(id);
      setContainers(container);
      setConststatus(container[0].status)
      // If successful, log it and return
      // console.log('First container fetched:', container);
      // return container;
    } catch (error) {
      console.error('First container fetch failed:', error);
      // If first fetch fails, try fetching the second container
      try {
        let container = await fetchingAllContainerItems();
        console.log('Second container fetched:', container);
        // return container2;
        const filteredContainers = container.filter(contain => contain.container === id);
          // Check if any matching containers were found
          if (filteredContainers.length > 0) {
              console.log('Filtered container found:', filteredContainers);
              // Set the status from the first matching container found
              setConststatus(filteredContainers[0].status);
              // Optionally set the container state to the filtered result
              setContainers(filteredContainers);
          } else {
              // No matching containers found in the second attempt
              console.log('No matching containers found after fetching all container items.');
              setConststatus(null); // Set status to null or any default value
              setContainers([]); // Optionally set to an empty array if no container matches
          }
      } catch (secondError) {
        console.error('Second container fetch failed as well:', secondError);
        // Handle the case where both API calls fail
      }
    }
  };
  

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  const handleDelete = async (userId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this Item?");
    if (isConfirmed) {
      try {
        await deleteItemMaster(userId);
        fetchContainer();
        toast.success('Item deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Item. Please try again.');
      }
    }
  };

  const handleDeleteAll = async () => {
    const isConfirmed = window.confirm("Are you sure you want to delete The Container?");
    if (isConfirmed) {
      try {
        await deleteContainerFromMaster(id);
        window.location.reload(); // Refresh the entire page
        toast.success('Container deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Container. Please try again.');
      }
    }
  };

  const handleInputChange = (e, id, field) => {
    const updatedContainer = container.map(contain => {
      if (contain.id === id) {
        return { ...contain, [field]: e.target.value };
      }
      return contain;
    });
    setContainers(updatedContainer);
  };

  const handleReceiveContainer = async () => {
    // Check if any quantity is below 0
    const hasInvalidQuantity = container.some(contain => contain.masterqty < 0);
  
    if (hasInvalidQuantity) {
      toast.error('Cannot receive container. Some items have quantities below 0.');
      return; // Prevent further execution
    }
  
    try {
      // Construct the payload with the updated container details
      const updatedContainer = container.map(contain => ({
        ...contain,
        status: 1 // Update status to 'received'
      }));
  
      const response = await receiveContainer(updatedContainer);
  
      if (response.status === 200) {
        toast.success('Container received successfully.');
        fetchContainer();
      } else {
        toast.error('Error receiving container.');
      }
    } catch (error) {
      console.error('Error receiving container:', error);
      toast.error('Error receiving container. Please try again.');
    }
  };
  

  // for adding pending items 
  const submitaddedItemtoContainer = async (e) => {
    e.preventDefault();
    try {
        const firstContainer = container[0]; // Access the first object in the array
        // Construct the payload with the updated container details
        const payload = {
          // Add all the necessary fields from your form data
          code: formData.code,
          item: formData.item,
          status: 0,
          masterqty: formData.masterqty,
          saleprice: formData.saleprice,
          container: firstContainer.container,
          label: firstContainer.label,
          containerID: id ,  
          labelid: firstContainer.labelid,  
          username: localStorage.getItem('username')
        };

      console.log(payload)
  
      const response = await additemtoContainer(payload);
      // console.log(response)
  
      if (response.status === 200) {
        toast.success('Form data submitted successfully.');
        fetchContainer()
      } else {
        toast.error('Error submitting form data.');
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
      toast.error('Error submitting form data. Please try again.');
    }
  };
  
  // for adding received items items 
  const submitaddedItemtoContainer2 = async (e) => {
    e.preventDefault();
    try {
        const firstContainer = container[0]; // Access the first object in the array
      // Construct the payload with the updated container details
      const payload = {
        // Add all the necessary fields from your form data
        code: formData.code,
        item: formData.item,
        status: 1,
        masterqty: formData.masterqty,
        saleprice: formData.saleprice,
        label: firstContainer.label,
        container: firstContainer.container,
        containerID: id ,  
        labelid: firstContainer.labelid,  
        username: localStorage.getItem('username')
      };

      console.log(payload)
  
      const response = await additemtoContainer(payload);
      // console.log(response)
  
      if (response.status === 200) {
        toast.success('Form data submitted successfully.');
        fetchContainer()
      } else {
        toast.error('Error submitting form data.');
      }
    } catch (error) {
      console.error('Error submitting form data:', error);
      toast.error('Error submitting form data. Please try again.');
    }
  };

  // for search 
  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter items based on search query
  const filteredItems = container.filter(item =>
    item.item.toLowerCase().includes(searchQuery.toLowerCase())
  );

    return (
        <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-4'>

            {/* {conststatus === 0 ? 
                <button className='btn btn-success float-end btn-sm' onClick={handleReceiveContainer}>Receive Container</button>  
            : <button className='btn btn-success float-end' disabled >Received</button>} */}

            {/* {conststatus === 0 ? <button className='btn btn-danger float-end' onClick={handleReceiveContainer}>Receive Container</button> : <button className='btn btn-success float-end' disabled >Received</button>} */}

            {conststatus === 0 ? 
                <div>
                  <button className='btn btn-success float-end btn-sm' onClick={handleReceiveContainer}>Receive Container</button>  
                  <button className='btn btn-danger float-end btn-sm mx-1' onClick={handleDeleteAll}>Delete Container</button>
                </div>
            : <button className='btn btn-success float-end' disabled >Received</button>}
            

            <input
                className='form-control w-25 float-end mx-1'
                type="text"
                placeholder="Search items..."
                value={searchQuery}
                onChange={handleSearchChange}
            />

            <h6><u className='titilescolor'>Container Details</u></h6>

            <div className='mt-4'>
            {conststatus === 0 ?
            <form onSubmit={submitaddedItemtoContainer}>
                {/* Your form inputs */}
                
                <div className='d-flex'>
                    <div className='mx-1'>
                    <label>Code</label>
                    <input
                        required
                        className='form-control'
                        name="code"
                        value={formData.code}
                        onChange={handleChange}
                        min="0"  // Set minimum value to 0
                    />
                    </div>
                    <div className='mx-1'>
                    <label>Item</label>
                    <input
                        required
                        className='form-control'
                        type="text"
                        name="item"
                        value={formData.item}
                        onChange={handleChange}
                    />
                    </div>
                    <div className='mx-1'>
                    <label>QTY</label>
                    <input
                        required
                        className='form-control'
                        type="number"
                        name="masterqty"
                        value={formData.masterqty}
                        onChange={handleChange}
                        min="0"  // Set minimum value to 0
                    />
                    </div>
                    <div className='mx-1'>
                    <label>Price</label>
                    <input
                        required
                        className='form-control'
                        type="number"
                        name="saleprice"
                        value={formData.saleprice}
                        onChange={handleChange}
                    />
                    </div>
                    <div>
                    <button className='btn btn-primary mt-4' type="submit">Submit</button>
                    </div>
                </div>
                </form>
                : <div>
                <form onSubmit={submitaddedItemtoContainer2}>
                {/* Your form inputs */}
                <div className='d-flex'>
                    <div className='mx-1'>
                    <label>Code</label>
                    <input
                        required
                        className='form-control'
                        name="code"
                        value={formData.code}
                        onChange={handleChange}
                        min="0"  // Set minimum value to 0
                    />
                    </div>
                    <div className=''>
                    <label>Item</label>
                    <input
                        required
                        className='form-control'
                        type="text"
                        name="item"
                        value={formData.item}
                        onChange={handleChange}
                    />
                    </div>
                    <div className='mx-1'>
                    <label>QTY</label>
                    <input
                        required
                        className='form-control'
                        type="number"
                        name="masterqty"
                        value={formData.masterqty}
                        onChange={handleChange}
                    />
                    </div>
                    <div className='mx-1'>
                    <label>Price</label>
                    <input
                        required
                        className='form-control'
                        type="number"
                        name="saleprice"
                        value={formData.saleprice}
                        onChange={handleChange}
                    />
                    </div>
                    <div>
                    <button className='btn btn-warning mt-4' type="submit">Add</button>
                    </div>
                </div>
                </form>
                </div>}
            </div>

            <table className="table table-striped">
            <thead>
              <tr className='theads'>
                <td><strong>#</strong></td>
                <th>Code</th>
                <th>Item</th>
                <th>QTY</th>
                <th>Price</th>
                <th>Container</th>
                <th>Status</th>
                <th>User</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.filter(contain => contain).map((contain, index) => (
                <tr key={contain.id}>
                  <td>{index + 1}</td>
                  <td>{contain.code}</td>
                  <td>{contain.item}</td>
                  <td>
                    <input
                        className='form-control'
                        type="number"
                        value={contain.masterqty !== undefined ? contain.masterqty : contain.qty}
                        onChange={(e) => handleInputChange(e, contain.id, 'masterqty')}
                    />
                  </td>
                  <td>{contain.saleprice}</td>
                  <td>{contain.container}</td>
                  <td>{contain.status === 0 ? <div className='text-danger'>Pending</div> : <div className='text-success'>Received</div>}</td>
                  <td>{contain.user}</td>
                  <td>
                    {contain.status === 0 ? <i className="fa-solid fa-delete-left fa-1x deleteicon" onClick={() => handleDelete(contain.id)} style={{ paddingLeft: '10px'}}></i> : <div></div>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ToastContainer />
          </div>
          }
          
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
    )
}
