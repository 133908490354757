import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders'
import Expenseform from '../../components/Expenseform';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchingAllExpenses, createExpense, updateExpense, deleteExpense } from '../../api/api';

export default function Expenses() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  // logic 
  const [expenses, setEpenses] = useState([]);
  
  const [formData, setFormData] = useState({
    expense: '',
    amount: '',
    username: localStorage.getItem('username')
  });
  const [selectedExpense, setSelectedTask] = useState(null);

  useEffect(() => {
    fetchExpenses();
  }, []);

  const fetchExpenses = async () => {
    try {
      const expenses = await fetchingAllExpenses();
      console.log(expenses);
      setEpenses(expenses);
    } catch (error) {
      console.error('Error fetching expenses:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createExpense(formData);
      fetchExpenses();
      toast.success('Expense added successfully.!');
    } catch (error) {
      toast.error('Error adding Expense. Please try again.');
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateExpense(selectedExpense.id, formData);
      fetchExpenses();
      toast.success('Expense updated successfully.');
      setSelectedTask(null);
    } catch (error) {
      toast.error('Error updating Expense. Please try again.');
    }
  };

  const handleUpdate = (expense) => {
    setSelectedTask(expense);
    setFormData({
      expense: expense.expense,
      amount: expense.amount
   });
  };

  const handleDelete = async (userId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this Expense?");
    if (isConfirmed) {
      try {
        await deleteExpense(userId);
        fetchExpenses();
        toast.success('Expense deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Expense. Please try again.');
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-4'>

            <button className='btn btn-success btn-sm float-end' data-bs-toggle="modal" data-bs-target="#exampleModal">Create Expense</button>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h6 className="modal-title fs-5" id="exampleModalLabel">Add Expense</h6>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <Expenseform formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
                  </div>
                </div>
              </div>
            </div>

            <h6><u className='titilescolor'>Expenses Dashboard</u></h6>
            <table className="table table-striped">
            <thead>
              <tr className='theads'>
                <td><strong>#</strong></td>
                <th>Expense</th>
                <th>Amount</th>
                <th>User</th>
                <th>Created</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {expenses.map((expense, index) => (
                <tr key={expense.id}>
                  <td>{index + 1}</td>
                  <td>{expense.expense}</td>
                  <td>{expense.amount}</td>
                  <td>{expense.username}</td>
                  <td>{expense.createdAt}</td>
                  <td>
                    <i className="fa-solid fa-pen-to-square fa-1x editicon" onClick={() => handleUpdate(expense)} data-bs-toggle="modal" data-bs-target="#exampleModal2"></i>
                    <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h6 className="modal-title fs-5" id="exampleModalLabel2">Update Expense</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div className="modal-body">
                            <Expenseform formData={formData} handleChange={handleChange} handleSubmit={handleUpdateSubmit} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <i className="fa-solid fa-delete-left fa-1x deleteicon" onClick={() => handleDelete(expense.id)} style={{ paddingLeft: '10px'}}></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ToastContainer />
          </div>
          }
          
          
          
          
          
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
