import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../components/Sidepanel';
import Navbar from '../components/Navbar';
import Dashboard from '../pages/dashboard/Dashboard';
import Users from '../pages/homepage/Users';
import Customers from '../pages/homepage/Customers';
import Tasks from '../pages/homepage/Tasks';
import Notes from '../pages/homepage/Notes';
import Orders from '../pages/homepage/Orders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchsalesDetails, deleteComplain, fetchingAllSellers, deleteSaleitem, makeSale, fetchingComplains, fetchAllItemsMaster } from '../api/api';
import Goodcomplainform from '../components/goodcomplainform';

export default function InvoiceDetails() {
  const { id } = useParams(); // Get the sales id from the route parameters
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [searchQuery, setSearchQuery] = useState('');
  const [sellers, setSellers] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [bank, setBank] = useState('');
  const [mpesa, setMpesa] = useState('');
  const [cash, setCash] = useState('');
  const [etrNumber, setEtrNumber] = useState('');
  const [commission, setCommission] = useState('');
  const [mastersheetitems, setMastersheetitems] = useState('');
  const [sellerId, setSellerId] = useState('');
  const [paymentmethod, setPaymentmethod] = useState('');
  const [commissions, setCommissions] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [complains, setComplains] = useState([]);
  const [totalGoodComplains, setTotalGoodComplains] = useState(0);
  const [customerName, setCustomername] = useState('')

  // Function to calculate the total amount
  const calculateTotalGoodComplains = () => {
    const total = complains.reduce((acc, item) => acc + (item.amount * item.qty), 0);
    setTotalGoodComplains(total);
  };

  // Call the function when complains change
  useEffect(() => {
    calculateTotalGoodComplains();
  }, [complains]);


  const handleRefresh = () => {
    console.log('refreshing this thing')
    setRefresh(!refresh);
  };

  const handleFormSubmit = () => {
    console.log("Form has been submitted");
  };

  const handleComplainSubmit = (data) => {
    // Set the submitted data (this data comes from Goodcomplainform)
    console.log("Complain submitted:", data); // You can also print it here
  };

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  // logic 
  const [sales, setSales] = useState([]);
  const [formData, setFormData] = useState({
    sale: '',
    amount: '',
    username: localStorage.getItem('username')
  });
  const [selectedLabel, setSelectedTask] = useState(null);

  const [complainformData, setComplainformData] = useState({
    saleid: id
  })

  const handleComplainChange = (e) => {
    setComplainformData({ ...complainformData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    fetchLabelItems();
    fetchSellers();
    fetchAllmasterSheetitems()
    fetchComplainsForthissale()
  }, [refresh]);

  const fetchComplainsForthissale = async () => {
    try {
      const complains = await fetchingComplains();
      // Filter complains where itemsaleid matches the provided id
      const filteredComplains = complains.filter(complain => complain.itemsaleid === id);
      console.log('Filtered complains', filteredComplains);

      setComplains(filteredComplains);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const fetchLabelItems = async () => {
    try {
      const sales = await fetchsalesDetails(id);
      console.log('sales', sales);
      setSales(sales);
      setCustomername(sales[0].customer);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const fetchAllmasterSheetitems = async () => {
    try {
      const masteritems = await fetchAllItemsMaster();
      console.log('masteritems', masteritems);
      setMastersheetitems(masteritems);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const fetchSellers = async () => {
    try {
      const sellers = await fetchingAllSellers();
      console.log(sellers);
      setSellers(sellers);
    } catch (error) {
      console.error('Error fetching sellers:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };  

  const handleDelete = async (saleId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this Item?");
    if (isConfirmed) {
      try {
        await deleteSaleitem(saleId);
        fetchLabelItems();
        toast.success('Item deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Item. Please try again.');
      }
    }
  };

  const handleDeleteComplain = async (itemid) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this Complain?");
    if (isConfirmed) {
      try {
        await deleteComplain(itemid);
        fetchComplainsForthissale();
        toast.success('Complain deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Complain. Please try again.');
      }
    }
  };

  const handleInputChange = (e, id, field) => {
    const updatedContainer = sales.map(sale => {
      if (sale.id === id) {
        return { ...sale, [field]: e.target.value };
      }
      return sale;
    });
    setSales(updatedContainer);
  };

  const handleSell = async (invoiceNumber, bank, mpesa, cash,  etrNumber, commission, paymentmethod, totalCommission) => {
    try {
      const formData = {
        // Include invoice number, ETR number, commission, and seller ID in the request payload
        invoiceNumber,
        bank, 
        mpesa, 
        cash,
        etrNumber,
        commission,
        paymentmethod,
        sellerId,
        saleId: id,
        totalCommission
      };

      // console.log(formData)
      await makeSale(formData);
      navigate('/invoices');
    } catch (error) {
      toast.error('Error when selling. Please try again.');
    }
  };

  // Update state variables when input fields change
  const handleInvoiceNumberChange = (e) => {
    setInvoiceNumber(e.target.value);
  };

  const handleBankChange = (e) => {
    setBank(e.target.value);
  };

  const handleMpesaChange = (e) => {
    setMpesa(e.target.value);
  };

  const handleCashChange = (e) => {
    setCash(e.target.value);
  };

  const handleEtrNumberChange = (e) => {
    setEtrNumber(e.target.value);
  };

  const handleCommissionChange = (e, saleId) => {
    const { value } = e.target;
    setCommissions({ ...commissions, [saleId]: parseFloat(value) || 0 });

    // Update the saletotal based on the new commission
    setSales(sales.map(sale => {
      if (sale.id === saleId) {
        const newTotal = sale.saleprice * sale.saleqty;
        const totalCommission = parseFloat(value) * sale.saleqty;
        return { ...sale, saletotal: newTotal - totalCommission };
      }
      return sale;
    }));
  };

  const handleSellerIdChange = (e) => {
    setSellerId(e.target.value);
    console.log('Selected Seller ID:', e.target.value);  // Debugging log
  };

  const handlepaymentmethodChange = (e) => {
    setPaymentmethod(e.target.value);
  };

  // const handleSellClick = (invoiceNumber, etrNumber, commission, paymentmethod) => {
  //   const totalCommission = sales.reduce((total, sale) => total + (commissions[sale.id] ? commissions[sale.id] * sale.saleqty : 0), 0);
  //   handleSell(invoiceNumber, etrNumber, commission, paymentmethod, totalCommission);
  // };
  const handleSellClick = () => {
    const totalCommission = sales.reduce((total, sale) => total + (commissions[sale.id] ? commissions[sale.id] * sale.saleqty : 0), 0);
    handleSell(invoiceNumber, bank, mpesa, cash, etrNumber, commission, paymentmethod, totalCommission);
  };

  const totalAmount = sales.reduce((total, sale) => total + parseFloat(sale.saletotal), 0);
  const totalCommision = sales.reduce((total, sale) => total + (commissions[sale.id] ? commissions[sale.id] * sale.saleqty : 0), 0);

  // for search 
  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter items based on search query
  const filteredItems = sales.filter(item =>
    item.customer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Helper function to check if there are any unsold items
  const hasUnsoldItems = sales.some(sale => sale.salestatus === 0 || sale.salestatus === 2);

  // Convert bank, mpesa, and cash to numbers
  const bankAmount = parseFloat(bank) || 0;
  const mpesaAmount = parseFloat(mpesa) || 0;
  const cashAmount = parseFloat(cash) || 0;

  // Calculate the balance
  const balance = totalAmount - (bankAmount + mpesaAmount + cashAmount + totalGoodComplains);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('submiting')
      // await createComplain(complainformData);
      // toast.success('Complain added successfully.!');
      // // Refresh the page
      // navigate(0);
    } catch (error) {
      toast.error('Error adding Complain. Please try again.');
    }
  };

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />

        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' &&
            <div className='mt-4'>
             
              {!hasUnsoldItems && sales.length > 0 && (
                <>
                  <Link to={`/solditemsdetails/${sales[0].saleid}`}>
                    <button className='btn btn-success mx-1 float-end btn-sm'>Invoice</button>
                  </Link>
                </>
              )}

              <h6><u className='titilescolor'>Sale Details</u></h6>
              <table className="table table-striped">
                <thead>
                  <tr className='theads'>
                    <td><strong>#</strong></td>
                    <th>Item</th>
                    <th>Label</th>
                    <th>QTY</th>
                    <th>Price</th>
                    <th>Total</th>
                    {!sales.some(sale => sale.saletype === 'wholesale') && (
                      <>
                        <th>Total Commission</th>
                        <th>Input Commission</th>
                        {/* <th>Seller</th> */}
                      </>
                    )}
                    <th>Sale Type</th>
                    <th>Status</th>
                    <th>Customer</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredItems.filter(sale => sale).map((sale, index) => (
                    <tr key={sale.id}>
                      <td>{index + 1}</td>
                      <td>{sale.itemid.item}</td>
                      <td>{sale.itemid.label}</td>
                      <td>{sale.saleqty}</td>
                      <td>{sale.saleprice}</td>
                      <td>{sale.saletotal}</td>
                      {!sale.saletype.includes('wholesale') && (
                        <>
                          <td>{commissions[sale.id] ? commissions[sale.id] * sale.saleqty : 0}</td>
                          <td>
                            <input
                              type="number"
                              min="0"
                              className='form-control'
                              value={commissions[sale.id] !== undefined ? commissions[sale.id] : 0}
                              onChange={(e) => handleCommissionChange(e, sale.id)}
                            />
                          </td>
                          {/* <td>
                            <select className="form-select" value={sellerId} onChange={handleSellerIdChange}>
                              <option disabled selected value="">Seller</option>
                              {sellers && sellers.map(seller => (
                                <option key={seller.id} value={seller.id}>{seller.sellername}</option>
                              ))}
                            </select>
                          </td> */}
                        </>
                      )}
                      <td>{sale.saletype}</td>
                      <td>{sale.salestatus === 0 || sale.salestatus === 2 ? <p className='text-danger fw-bold'>Not sold</p> : <p className='text-success fw-bold'>Sold</p>}</td>
                      <td>{sale.customer}</td>
                      <td>
                        {sale.salestatus === 0 || sale.salestatus === 2 ?
                          <i className="fa-solid fa-delete-left fa-1x" onClick={() => handleDelete(sale.id)} style={{ paddingLeft: '10px', color: 'red' }}></i>
                          :
                          <div></div>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {hasUnsoldItems && (
                <>
                  <thead>
                    <tr>
                      <td><strong>#</strong></td>
                      {!sales.some(sale => sale.saletype === 'wholesale') && (
                        <>
                          <th>
                            <select className="form-select" aria-label="Default select example" value={sellerId} onChange={handleSellerIdChange}>
                              <option disabled selected value="">Seller</option>
                              {sellers && sellers.map(seller => (
                                <option key={seller.id} value={seller.id}>{seller.sellername}</option>
                              ))}
                            </select>
                          </th>
                        </>
                        )}
                         <>
                          <th>
                            <input type="number" name='bank' required className="form-control" id="bank" placeholder='Bank' onChange={handleBankChange} />
                          </th>
                          <th>
                            <input type="number" name='mpesa' required className="form-control" id="mpesa" placeholder='Mpesa' onChange={handleMpesaChange} />
                          </th>
                          <th>
                            <input type="number" name='cash' required className="form-control" id="cash" placeholder='Cash' onChange={handleCashChange} />
                          </th>
                          <th>
                            <input type="text" name='invoicenumber' required className="form-control" id="invoicenumber" placeholder='Enter invoice number' onChange={handleInvoiceNumberChange} />
                          </th>
                          <th>
                            <input type="text" name='etrnumber' required className="form-control" id="etrnumber" placeholder='Enter ETR number' onChange={handleEtrNumberChange} />
                          </th>
                        </>
                      <th>
                        <button onClick={handleSellClick} className='btn btn-success'>Sell</button>
                        {/* <button onClick={() => handleSellClick(invoiceNumber, bank, mpesa, cash, etrNumber, commission, sellerId, paymentmethod)} className='btn btn-success'>Sell</button> */}
                      </th>
                    </tr>
                  </thead>

                  <h6 className='text-primary mt-4'>Good Complain</h6>
                  <Goodcomplainform formData={complainformData} customerName={customerName} handleChange={handleComplainChange} onFormSubmit={handleFormSubmit} masteritems={mastersheetitems} onRefresh={handleRefresh} onComplainSubmit={handleComplainSubmit}  handleSubmit={handleSubmit} saleId={id} />
                        
                  <tabel className="table table-striped">
                    <thead>
                      <tr className='theads'>
                        <th>Item</th>
                        <th>Label</th>
                        <th>QTY</th>
                        <th>Amount</th>
                        <th>Total</th>
                      </tr>
                    </thead>

                    <tbody>
                      {complains.map(item => (
                        <tr>
                          <td> {item.itemname}</td>
                          <td> {item.itemlabel}</td>
                          <td> {item.qty}</td>
                          <td> {item.amount}</td>
                          <td> {item.amount * item.qty}</td>
                          <td>
                          <i className="fa-solid fa-delete-left fa-1x" onClick={() => handleDeleteComplain(item.id)} style={{ paddingLeft: '10px', color: 'red' }}></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </tabel>
                
                </>
              )}
              
              <h5 className='text-primary mt-4'>Sub Total: {totalAmount}</h5>
              <h6 className='text-success'>Bank: {bank}</h6>
              <h6 className='text-success'>Mpesa: {mpesa}</h6>
              <h6 className='text-success'>Cash: {cash}</h6>
              <h6 className='text-info'>Good Complain: {totalGoodComplains}</h6>
              <h6 className='text-danger'>Balance: {balance}</h6>
              {!sales.some(sale => sale.saletype === 'wholesale') && (
                <h6 className='text-danger'>Total Commision: {totalCommision}</h6>
              )}

              <ToastContainer />
            </div>
          }

          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  )
}