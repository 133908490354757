// api.js
import axios from 'axios';
import logger from '../utils/logger'; // Import the logger
import { getAuthHeaders } from '../utils/auth'; // Import getAuthHeaders from utils

// const loggedinuserID = localStorage.getItem('userid')
// const BASE_URL = process.env.REACT_APP_SERVER_API;
// const BASE_URL = 'https://backend.aptech.co.ke';
// const BASE_URL = 'https://apibackend.insia.co.ke';
// const BASE_URL = 'https://insia.co.ke';
const BASE_URL = 'https://backend.svtrading.co.ke';

// Login API 
export const login = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/login`, userData);
    logger.info('User logged in successfully:', response.data);
    return response;
  } catch (error) {
    logger.error('Error logging in user:', error);
    throw new Error('Error adding user:', error);
  }
};

// Users API ***********************************************
export const fetchAllUsers = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingAllUsers`, getAuthHeaders());
    logger.info('Fetched all users successfully');
    return response.data;
  } catch (error) {
    logger.error('Error fetching users:', error);
    throw new Error('Error fetching users:', error);
  }
};

export const createUser = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/createUser`, userData, getAuthHeaders());
    logger.info('User created successfully:', response.data);
    return response;
  } catch (error) {
    logger.error('Error adding user:', error);
    // throw new Error('Error adding user:', error);
    throw error;

  }
};

export const updateUser = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateUser/${userId}`, userData, getAuthHeaders());
    logger.info('User updated successfully:', response.data);
    return response;
  } catch (error) {
    logger.error('Error updating user:', error);
    throw new Error('Error updating user:', error);
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteUser/${userId}`, getAuthHeaders());
    logger.info('User deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error deleting user:', error);
    throw new Error('Error deleting user:', error);
  }
};

// Customers API ***************************************************
export const fetchAllCustomers = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingAllCustomers`, getAuthHeaders());
    logger.info('Fetched all customers successfully');
    return response.data;
  } catch (error) {
    logger.error('Error fetching customers:', error);
    throw new Error('Error fetching customers:', error);
  }
};

export const createCustomer = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/createCustomer`, userData, getAuthHeaders());
    logger.info('Customer created successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error adding customer:', error);
    // throw new Error('Error adding customer:', error);
    throw error;
  }
};

export const updateCustomer = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateCustomer/${userId}`, userData, getAuthHeaders());
    logger.info('Customer updated successfully:', response.data);
    return response;
  } catch (error) {
    logger.error('Error updating customer:', error);
    throw new Error('Error updating customer:', error);
  }
};

export const deleteCustomer = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteCustomer/${userId}`, getAuthHeaders());
    logger.info('Customer deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error deleting customer:', error);
    throw new Error('Error deleting customer:', error);
  }
};

// Tasks API ************************************************
export const fetchAllTasks = async (userid) => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchAllTasks/${userid}`, getAuthHeaders());
    logger.info('Fetched all tasks successfully for user:', userid);
    return response.data;
  } catch (error) {
    logger.error('Error fetching tasks:', error);
    throw new Error('Error fetching tasks:', error);
  }
};

export const createTask = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/createTask`, userData, getAuthHeaders());
    logger.info('Task created successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error adding task:', error);
    throw new Error('Error adding task:', error);
  }
};

export const updateTask = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateTask/${userId}`, userData, getAuthHeaders());
    logger.info('Task updated successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error updating task:', error);
    throw new Error('Error updating task:', error);
  }
};

export const deleteTask = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteTask/${userId}`, getAuthHeaders());
    logger.info('Task deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error deleting task:', error);
    throw new Error('Error deleting task:', error);
  }
};

// Notes API *******************************************************
export const fetchingAllNotes = async (userid) => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingAllNotes/${userid}`, getAuthHeaders());
    logger.info('Fetched all notes successfully for user:', userid);
    return response.data;
  } catch (error) {
    logger.error('Error fetching notes:', error);
    throw new Error('Error fetching notes:', error);
  }
};

export const createNote = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/createNote`, userData, getAuthHeaders());
    logger.info('Note created successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error adding note:', error);
    throw new Error('Error adding note:', error);
  }
};

export const updateNote = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateNote/${userId}`, userData, getAuthHeaders());
    logger.info('Note updated successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error updating note:', error);
    throw new Error('Error updating note:', error);
  }
};

export const deleteNote = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteNote/${userId}`, getAuthHeaders());
    logger.info('Note deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error deleting note:', error);
    throw new Error('Error deleting note:', error);
  }
};

// Labels API 
export const fetchingAllLabels = async (userid) => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingAllLabels`, getAuthHeaders());
    logger.info('Fetched all labels successfully');
    return response.data;
  } catch (error) {
    logger.error('Error fetching labels:', error);
    throw new Error('Error fetching labels:', error);
  }
};

export const createLabel = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/createLabel`, userData, getAuthHeaders());
    logger.info('Label created successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error adding label:', error);
    throw new Error('Error adding label:', error);
  }
};

export const fetchLabelDetails = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchLabelDetails/${id}`, getAuthHeaders());
    logger.info('Fetched label details successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error fetching label details:', error);
    throw new Error('Error fetching label:', error);
  }
};

export const updateLabel = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateLabel/${userId}`, userData, getAuthHeaders());
    logger.info('Label updated successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error updating label:', error);
    throw new Error('Error updating label:', error);
  }
};

export const deleteLabel = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteLabel/${userId}`, getAuthHeaders());
    logger.info('Label deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error deleting label:', error);
    throw new Error('Error deleting label:', error);
  }
};

// Items API ****************************************
export const fetchAllItems = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchAllItems`, getAuthHeaders());
    return response.data;
  } catch (error) {
    logger.error('Error fetching items:', error);
    throw new Error('Error fetching items: ' + error.message);
  }
};

export const createItem = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/createItem`, userData, getAuthHeaders());
    logger.info('Item created successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error adding item:', error);
    throw new Error('Error adding item: ' + error.message);
  }
};

export const updateItem = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateItem/${userId}`, userData, getAuthHeaders());
    logger.info('Item updated successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error updating item:', error);
    throw new Error('Error updating item: ' + error.message);
  }
};

export const importItemsCSV = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/importItemsCSV`, formData, getAuthHeaders());
    logger.info('Items imported from CSV successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error importing items from CSV:', error);
    throw new Error('Error importing items from CSV: ' + error.message);
  }
};

export const deleteItem = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteItem/${userId}`, getAuthHeaders());
    logger.info('Item deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error deleting item:', error);
    throw new Error('Error deleting item: ' + error.message);
  }
};

// Items to Mastersheet API ****************************************
export const fetchAllItemsMaster = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchAllItemsMaster`, getAuthHeaders());
    return response.data;
  } catch (error) {
    logger.error('Error fetching items from mastersheet:', error);
    throw new Error('Error fetching items from mastersheet: ' + error.message);
  }
};

export const createItemMaster = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/createItemMaster`, userData, getAuthHeaders());
    logger.info('Item from mastersheet added successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error adding item from mastersheet:', error);
    throw new Error('Error adding item from mastersheet: ' + error.message);
  }
};

export const updateStockMaster = async (updatedStocks) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateStockMaster`, { updatedStocks }, getAuthHeaders());
    logger.info('Stock updated successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error updating stock from mastersheet:', error);
    throw new Error('Error updating stock from mastersheet: ' + error.message);
  }
};

export const updateMaxnum = async (formData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateMaxnum`, formData, getAuthHeaders());
    logger.info('Maxnum updated successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error updating maxnum from mastersheet:', error);
    throw new Error('Error updating maxnum from mastersheet: ' + error.message);
  }
};

export const pushtows = async (labelid, value) => {
  try {
    const response = await axios.patch(`${BASE_URL}/pushtows/${labelid}`, { value }, getAuthHeaders());
    logger.info('Data pushed successfully to the warehouse:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error updating warehouse data:', error);
    throw new Error('Error updating warehouse data: ' + error.message);
  }
};

export const deleteItemMaster = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteItemMaster/${userId}`, getAuthHeaders());
    logger.info('Item from mastersheet deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error deleting item from mastersheet:', error);
    throw new Error('Error deleting item from mastersheet: ' + error.message);
  }
};


export const deleteItemInContainDetails = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteItemInContainDetails/${id}`, getAuthHeaders());
    logger.info('Item from mastersheet deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error deleting item from mastersheet:', error);
    throw new Error('Error deleting item from mastersheet: ' + error.message);
  }
};

export const deleteContainerwithitsitems = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteContainerwithitsitems/${id}`, getAuthHeaders());
    logger.info('Item from mastersheet deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error deleting item from mastersheet:', error);
    throw new Error('Error deleting item from mastersheet: ' + error.message);
  }
};

export const deleteContainerFromMaster = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteContainerFromMaster/${id}`, getAuthHeaders());
    logger.info('Container from mastersheet deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error deleting container from mastersheet:', error);
    throw new Error('Error deleting container from mastersheet: ' + error.message);
  }
};

// Expenses API **********************************************
export const fetchingAllExpenses = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingAllExpenses`, getAuthHeaders());
    return response.data;
  } catch (error) {
    logger.error('Error fetching expenses:', error);
    throw new Error('Error fetching expenses: ' + error.message);
  }
};

export const createExpense = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/createExpense`, userData, getAuthHeaders());
    logger.info('Expense added successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error adding expense:', error);
    throw new Error('Error adding expense: ' + error.message);
  }
};

export const updateExpense = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateExpense/${userId}`, userData, getAuthHeaders());
    logger.info('Expense updated successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error updating expense:', error);
    throw new Error('Error updating expense: ' + error.message);
  }
};

export const deleteExpense = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteExpense/${userId}`, getAuthHeaders());
    logger.info('Expense deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error deleting expense:', error);
    throw new Error('Error deleting expense: ' + error.message);
  }
};

// Seller API **********************************************
export const fetchingAllSellers = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingAllSellers`, getAuthHeaders());
    return response.data;
  } catch (error) {
    logger.error('Error fetching sellers:', error);
    throw new Error('Error fetching sellers: ' + error.message);
  }
};

export const fetchingAllCommisions = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingAllCommisions`, getAuthHeaders());
    return response.data;
  } catch (error) {
    logger.error('Error fetching commissions:', error);
    throw new Error('Error fetching commissions: ' + error.message);
  }
};

export const createSeller = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/createSeller`, userData, getAuthHeaders());
    logger.info('Seller added successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error adding seller:', error);
    throw error;
    // throw new Error('Error adding seller: ' + error.message);
  }
};

export const fetchCommision = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchCommision/${id}`, getAuthHeaders());
    return response.data;
  } catch (error) {
    logger.error('Error fetching commission:', error);
    throw new Error('Error fetching commission: ' + error.message);
  }
};

export const deleteCommision = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteCommision/${id}`, getAuthHeaders());
    logger.info('Commission deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error deleting commission:', error);
    throw new Error('Error deleting commission: ' + error.message);
  }
};

export const updateSeller = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateSeller/${userId}`, userData, getAuthHeaders());
    logger.info('Seller updated successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error updating seller:', error);
    throw new Error('Error updating seller: ' + error.message);
  }
};

export const deleteSeller = async (userId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteSeller/${userId}`, getAuthHeaders());
    logger.info('Seller deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error deleting seller:', error);
    throw new Error('Error deleting seller: ' + error.message);
  }
};

// container api **********************************************
export const fetchingAllContainers = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingAllContainers`, getAuthHeaders());
    logger.info('Fetched all containers successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error fetching containers:', error);
    throw new Error('Error fetching container: ' + error.message);
  }
};

export const fetchingAllContainerItems = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingAllContainerItems`, getAuthHeaders());
    logger.info('Fetched all containerItems successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error fetching containerItems:', error);
    throw new Error('Error fetching containerItems: ' + error.message);
  }
};

export const fetchContainerDetails = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchContainerDetails/${id}`, getAuthHeaders());
    logger.info('Fetched container details successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error fetching container details:', error);
    throw new Error('Error fetching container: ' + error.message);
  }
};

export const createContainer = async (userData) => {
  try {
    logger.info('Creating container with data:', userData);
    const response = await axios.post(`${BASE_URL}/createContainer`, userData, getAuthHeaders());
    logger.info('Container created successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error adding container:', error);
    throw new Error('Error adding container: ' + error.message);
  }
};

export const additemtoContainer = async (payload) => {
  try {
    logger.info('Adding item to container with payload:', payload);
    const response = await axios.post(`${BASE_URL}/additemtoContainer`, payload, getAuthHeaders());
    logger.info('Item added to container successfully:', response.data);
    return response;
  } catch (error) {
    logger.error('Error adding item to container:', error);
    throw new Error('Error adding item container: ' + error.message);
  }
};

export const receiveContainer = async (updatedContainer) => {
  try {
    logger.info('Receiving container with updated data:', updatedContainer);
    const response = await axios.patch(`${BASE_URL}/receiveContainer`, updatedContainer, getAuthHeaders());
    logger.info('Container updated successfully:', response.data);
    return response;
  } catch (error) {
    logger.error('Error updating container:', error);
    throw new Error('Error updating container: ' + error.message);
  }
};

export const updateContainer = async (userId, userData) => {
  try {
    logger.info('Updating container with ID:', userId, 'and data:', userData);
    const response = await axios.patch(`${BASE_URL}/updateContainer/${userId}`, userData, getAuthHeaders());
    logger.info('Container updated successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error updating container:', error);
    throw new Error('Error updating container: ' + error.message);
  }
};

export const deleteContainer = async (userId) => {
  try {
    logger.info('Deleting container with ID:', userId);
    const response = await axios.delete(`${BASE_URL}/deleteContainer/${userId}`, getAuthHeaders());
    logger.info('Container deleted successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error deleting container:', error);
    throw new Error('Error deleting container: ' + error.message);
  }
};

// sales API
export const creatingSale = async (saleData) => {
  try {
    logger.info('Creating sale with data:', saleData);
    const response = await axios.post(`${BASE_URL}/creatingSale`, saleData, getAuthHeaders());
    logger.info('Sale created successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error creating sale:', error);
    throw new Error('Error creating sale: ' + error.message);
  }
};

export const closeWholesale = async () => {
  try {
    const response = await axios.patch(`${BASE_URL}/closeWholesale`, getAuthHeaders());
    logger.info('Wholesale closed successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error updating sale:', error);
    throw new Error('Error updating sale: ' + error.message);
  }
};

export const creatingWholeSale = async (saleData) => {
  try {
    logger.info('Creating wholesale with data:', saleData);
    const response = await axios.post(`${BASE_URL}/creatingWholeSale`, saleData, getAuthHeaders());
    logger.info('Wholesale created successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error creating wholesale:', error);
    throw new Error('Error creating sale: ' + error.message);
  }
};

export const fetchAllSalesNoStatus = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchAllSalesNoStatus`, getAuthHeaders());
    logger.info('Fetched all sales without status successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error fetching sales:', error);
    throw new Error('Error fetching sales: ' + error.message);
  }
};

export const fetchAllSales = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchAllSales`, getAuthHeaders());
    logger.info('Fetched all sales successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error fetching sales:', error);
    throw new Error('Error fetching sales: ' + error.message);
  }
};

export const fetchsalesDetails = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchsalesDetails/${id}`, getAuthHeaders());
    logger.info('Fetched sales details successfully for ID:', id);
    return response.data;
  } catch (error) {
    logger.error('Error fetching sales details:', error);
    throw new Error('Error fetching sales details: ' + error.message);
  }
};

export const fetchAnalyticsDetails = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchAnalyticsDetails/${id}`, getAuthHeaders());
    logger.info('Fetched analytics details successfully for ID:', id);
    return response.data;
  } catch (error) {
    logger.error('Error fetching analytics details:', error);
    throw new Error('Error fetching sales details: ' + error.message);
  }
};

export const fetchAllSalesStatus = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchAllSalesStatus`, getAuthHeaders());
    logger.info('Fetched all sales status successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error fetching sales status:', error);
    throw new Error('Error fetching sales: ' + error.message);
  }
};

export const fetchAllSalesStatusForWholeSale = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchAllSalesStatusForWholeSale`, getAuthHeaders());
    logger.info('Fetched all sales status for wholesale successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error fetching sales status for wholesale:', error);
    throw new Error('Error fetching sales: ' + error.message);
  }
};

export const updateSale = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateSale/${userId}`, userData, getAuthHeaders());
    logger.info('Sale updated successfully for user ID:', userId);
    return response.data;
  } catch (error) {
    logger.error('Error updating sale:', error);
    throw new Error('Error updating sale: ' + error.message);
  }
};

export const deleteSale = async (saleId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteSale/${saleId}`, getAuthHeaders());
    logger.info('Sale deleted successfully for ID:', saleId);
    return response.data;
  } catch (error) {
    logger.error('Error deleting sale:', error);
    throw new Error('Error deleting sale: ' + error.message);
  }
};

export const editSaleItem = async (saleId, editedSaleData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/editSaleItem/${saleId}`, editedSaleData, getAuthHeaders());
    logger.info('Sale item edited successfully for sale ID:', saleId);
    return response.data;
  } catch (error) {
    logger.error('Error updating sale item:', error);
    throw new Error('Error updating sale: ' + error.message);
  }
};

export const deleteSaleitem = async (saleId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteSaleitem/${saleId}`, getAuthHeaders());
    logger.info('Sale item deleted successfully for sale ID:', saleId);
    return response.data;
  } catch (error) {
    logger.error('Error deleting sale item:', error);
    throw new Error('Error deleting sale: ' + error.message);
  }
};

// export const approveSale = async (saleId) => {
//   console.log('trying', saleId)
//   try {
//     const response = await axios.patch(`${BASE_URL}/approveSale/${saleId}`);
//     logger.info('Sale approved successfully for user ID:', response.data);
//     return response.data;
//   } catch (error) {
//     logger.error('Error approving sale:', error);
//     throw new Error('Error deleting sale: ' + error.message);
//   }
// };

export const approveSale = async (userId) => {
  try {
    const response = await axios.patch(`${BASE_URL}/approveSale/${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Error deleting sale:', error);
  }
};

export const makeSale = async (formData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/makeSale`, formData, getAuthHeaders());
    logger.info('Sale made successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error making sale:', error);
    throw new Error('Error deleting sale: ' + error.message);
  }
};

export const updatemakeSale = async (formData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updatemakeSale`, formData, getAuthHeaders());
    logger.info('Sale updated successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error updating sale:', error);
    throw new Error('Error deleting sale: ' + error.message);
  }
};

export const creditNoteItem = async (creditNoteData) => {
  try {
    logger.info('Updating credit note item with data:', creditNoteData);
    const response = await axios.patch(`${BASE_URL}/creditNoteItem`, creditNoteData, getAuthHeaders());
    logger.info('Credit note item updated successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error updating credit note item:', error);
    throw new Error('Error deleting sale: ' + error.message);
  }
};

// good complain 
export const createComplain = async (complainData) => {
  try {
    logger.info('Creating complain with data:', complainData);
    const response = await axios.post(`${BASE_URL}/createComplain`, complainData, getAuthHeaders());
    logger.info('Complain created successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error creating complain:', error);
    throw new Error('Error creating complain: ' + error.message);
  }
};

export const fetchingComplains = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchingComplains`, getAuthHeaders());
    logger.info('Fetched complains successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error fetching complains:', error);
    throw new Error('Error fetching complains: ' + error.message);
  }
};

export const updateComplain = async (userId, userData) => {
  try {
    const response = await axios.patch(`${BASE_URL}/updateComplain/${userId}`, userData, getAuthHeaders());
    logger.info('Complain updated successfully for user ID:', userId);
    return response.data;
  } catch (error) {
    logger.error('Error updating complain:', error);
    throw new Error('Error updating complain: ' + error.message);
  }
};

export const deleteComplain = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteComplain/${id}`, getAuthHeaders());
    logger.info('Complain deleted successfully for ID:', id);
    return response.data;
  } catch (error) {
    logger.error('Error deleting complain:', error);
    throw new Error('Error deleting complain: ' + error.message);
  }
};

// customer orders 
export const deleteOrder = async (saleId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/deleteOrder/${saleId}`, getAuthHeaders());
    logger.info('Order deleted successfully for sale ID:', saleId);
    return response.data;
  } catch (error) {
    logger.error('Error deleting order:', error);
    throw new Error('Error deleting order: ' + error.message);
  }
};

export const creatingOrder = async (orderData) => {
  try {
    logger.info('Creating order with data:', orderData);
    const response = await axios.post(`${BASE_URL}/creatingOrder`, orderData, getAuthHeaders());
    logger.info('Order created successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error creating order:', error);
    throw new Error('Error creating sale: ' + error.message);
  }
};

// stocks API 
export const fetchAllstocks = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/fetchAllstocks`, getAuthHeaders());
    logger.info('Fetched all stocks successfully:', response.data);
    return response.data;
  } catch (error) {
    logger.error('Error fetching stocks:', error);
    throw new Error('Error fetching stocks: ' + error.message);
  }
};
