import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { fetchAllSales } from '../../api/api';

export default function Salesreport() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [customerfilter, setCustomerFilter] = useState('all');
  const [itemFilter, setItemFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [customers, setCustomers] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [usdRate, setUsdRate] = useState(1);

  // Get today's date in the format 'YYYY-MM-DD'
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const [startDate, setStartDate] = useState(getTodayDate()); // Default to today's date
  const [endDate, setEndDate] = useState(getTodayDate()); 

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    filterItems();
  }, [items, customerfilter, itemFilter, startDate, endDate, usdRate, searchTerm]);

  const fetchItems = async () => {
    try {
      const items = await fetchAllSales();
      setItems(items);
      setFilteredItems(items);

      const uniqueItems = [...new Set(items.map(item => item.itemid.item))];
      setAllItems(uniqueItems);

      const uniqueCustomers = [...new Set(items.map(item => item.customer))];
      setCustomers(uniqueCustomers);

    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const filterItems = () => {
    let filtered = items;

    if (customerfilter !== 'all') {
      filtered = filtered.filter(item => item.customer === customerfilter);
    }

    if (itemFilter !== 'all') {
      filtered = filtered.filter(item => item.itemid.item === itemFilter);
    }

    if (startDate) {
      filtered = filtered.filter(item => {
        const itemDate = item.createdAt.split(' ')[0]; // Extract just the 'YYYY-MM-DD' part
        return new Date(itemDate) >= new Date(startDate);
      });
    }
  
    if (endDate) {
      filtered = filtered.filter(item => {
        const itemDate = item.createdAt.split(' ')[0]; // Extract just the 'YYYY-MM-DD' part
        return new Date(itemDate) <= new Date(endDate);
      });
    }

    if (searchTerm) {
      filtered = filtered.filter(item => 
        item.itemid.item.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredItems(filtered);
  };

  const handleCustomerFilterChange = (event) => {
    setCustomerFilter(event.target.value);
  };

  const handleItemFilterChange = (event) => {
    setItemFilter(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleUsdRateChange = (event) => {
    setUsdRate(event.target.value);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const calculateSubtotal = () => {
    return filteredItems.reduce((total, item) => total + item.saletotal, 0);
  };

  const calculateKSHPTotal = () => {
    return filteredItems.reduce((total, item) => total + item.saleprice, 0);
  };

  const calculateQTYsub = () => {
    return filteredItems.reduce((total, item) => total + (item.saleqty - item.cnqty), 0);
  };

  const calculateKSHSUBTotal = () => {
    return filteredItems.reduce((total, item) => total + item.saletotal, 0);
  };

  const calculateUsdSubtotal = () => {
    return filteredItems.reduce((total, item) => total + (item.saletotal / usdRate), 0);
  };

  const calculateUsdSubtotalprice = () => {
    return filteredItems.reduce((total, item) => total + (item.saleprice / usdRate), 0);
  };

  const csvHeaders = [
    { label: "Seller", key: "seller" },
    { label: "Customer", key: "customer" },
    { label: "Label", key: "label" },
    { label: "Price", key: "saleprice" },
    { label: "USD Price", key: "usdSalePrice" },
    { label: "QTY", key: "saleqty" },
    { label: "Total", key: "saletotal" },
    { label: "USD Total", key: "usdSaleTotal" },
    { label: "Item", key: "itemid.item" },
    { label: "Created", key: "createdAt" }
  ];

  const updatedFilteredItems = filteredItems.map(item => ({
    ...item,
    usdSalePrice: (item.saleprice / usdRate).toFixed(2),
    usdSaleTotal: (item.saletotal / usdRate).toFixed(2)
  }));

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {activeScreen === 'mastersheet' && (
            <div className='mt-4'>
              <h5><u className='titilescolor'>Sales Report</u></h5>
              <div className='d-flex justify-content-between'>
                <div className='mt-3'>
                  <input
                    type='number'
                    className='form-control d-inline-block w-auto me-2'
                    placeholder='USD Rate'
                    value={usdRate}
                    onChange={handleUsdRateChange}
                  />
                  <input
                    type='date'
                    className='form-control d-inline-block w-auto me-2'
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                  <input
                    type='date'
                    className='form-control d-inline-block w-auto me-2'
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                  <select className='form-select d-inline-block w-auto' value={customerfilter} onChange={handleCustomerFilterChange}>
                    <option value='all'>All Customers</option>
                    {customers.map((customer, index) => (
                      <option key={index} value={customer}>{customer}</option>
                    ))}
                  </select>
                  <select className='form-select d-inline-block w-auto' value={itemFilter} onChange={handleItemFilterChange}>
                    <option value='all'>All Items</option>
                    {allItems.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                  </select>
                  <input
                    type='text'
                    className='form-control d-inline-block w-auto me-2'
                    placeholder='Search by item'
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                  />
                  <CSVLink 
                    data={updatedFilteredItems}
                    headers={csvHeaders}
                    filename={"sales-report.csv"}
                    className="btn btn-success btn-sm ms-2 mt-2"
                  >
                    Export to CSV
                  </CSVLink>
                </div>
              </div>
              <div className='row text-center mt-4'>
                <table className="table table-striped">
                  <thead>
                    <tr className='theads'>
                      <td><strong>#</strong></td>
                      <th>Item</th>
                      <th>QTY</th>
                      <th>KSH.P</th>
                      <th>KSH.T</th>
                      <th>USD.P</th>
                      <th>USD.T</th>
                      <th>Label</th>
                      <th>Customer</th>
                      <th>Seller</th>
                      <th>Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredItems.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.itemid.item}</td>
                        <td>{item.saleqty - item.cnqty}</td>
                        <td>{item.saleprice}</td>
                        <td>{(item.saleqty - item.cnqty) * item.saleprice}</td>
                        <td>{(item.saleprice / usdRate).toFixed(2)}</td>
                        <td>{((item.saleqty - item.cnqty) * item.saleprice / usdRate).toFixed(2)}</td>
                        <td>{item.label}</td>
                        <td>{item.customer}</td>
                        <td>{item.seller}</td>
                        <td>{item.createdAt}</td>
                      </tr>
                    ))}
                    <tr>
                      <td className='text-success'><strong>Subtotal</strong></td>
                      <td colSpan="1"></td>
                      <td className='text-success'><strong>{calculateQTYsub()}</strong></td>
                      <td className='text-success'><strong>{calculateKSHPTotal()}</strong></td>
                      <td className='text-success'><strong>{calculateKSHSUBTotal()}</strong></td>
                      <td className='text-success'><strong>{calculateUsdSubtotalprice().toFixed(2)}</strong></td>
                      <td className='text-success'><strong>{calculateUsdSubtotal().toFixed(2)}</strong></td>
                      <td colSpan="5"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
