import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../components/Sidepanel';
import Navbar from '../components/Navbar';
import Dashboard from '../pages/dashboard/Dashboard';
import Users from '../pages/homepage/Users';
import Customers from '../pages/homepage/Customers';
import Tasks from '../pages/homepage/Tasks';
import Notes from '../pages/homepage/Notes';
import Orders from '../pages/homepage/Orders'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { deleteItemInContainDetails, fetchingAllContainerItems } from '../api/api';

export default function LabelDetails() {
  const { id } = useParams(); // Get the container id from the route parameters
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [searchQuery, setSearchQuery] = useState('');

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  // logic 
  const [container, setContainers] = useState([]);

  useEffect(() => {
    fetchLabelItems();
  }, []);

  const fetchLabelItems = async () => {
    try {
      const container = await fetchingAllContainerItems();
      const filteredContainers = container.filter(contain => contain.container === (id));
      console.log('the one', id, filteredContainers);
      setContainers(filteredContainers);
    } catch (error) {
      console.error('Error fetching container:', error);
    }
  };

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this Item?");
    if (isConfirmed) {
      try {
        await deleteItemInContainDetails(id);
        fetchLabelItems();
        toast.success('Item deleted successfully.');
      } catch (error) {
        toast.error('Error deleting Item. Please try again.');
      }
    }
  };
  
  // for search 
  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter items based on search query
  const filteredItems = container.filter(item =>
    item.item.toLowerCase().includes(searchQuery.toLowerCase())
  );

    return (
        <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        
        <div className={`container my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && 
          <div className='mt-4'>

            <input
                className='form-control w-25 float-end mx-1'
                type="text"
                placeholder="Search items..."
                value={searchQuery}
                onChange={handleSearchChange}
            />

            <h6><u className='titilescolor'>Container Details</u></h6>

            <table className="table table-striped">
            <thead>
              <tr className='theads'>
                <td><strong>#</strong></td>
                <th>Code</th>
                <th>Item</th>
                <th>QTY</th>
                <th>Sale Price</th>
                <th>Label</th>
                <th>Container</th>
                <th>User</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredItems.map((contain, index) => (
                <tr key={contain.id}>
                  <td>{index + 1}</td>
                  <td>{contain.code}</td>
                  <td>{contain.item}</td>
                  <td>{contain.qty}</td>
                  <td>{contain.saleprice}</td>
                  <td>{contain.label}</td>
                  <td>{contain.container}</td>
                  <td>{contain.user}</td>
                  <td>{contain.createdAt}</td>
                  <td>
                    <i className="fa-solid fa-delete-left fa-1x deleteicon" onClick={() => handleDelete(contain.id)} style={{ paddingLeft: '10px'}}></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ToastContainer />
          </div>
          }
          
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
    )
}
